/* tslint:disable */
/* eslint-disable */
/**
 * BackendFramework
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { Credentials } from "../models";
// @ts-ignore
import { PasswordResetData } from "../models";
// @ts-ignore
import { PasswordResetRequestData } from "../models";
// @ts-ignore
import { User } from "../models";
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {Credentials} credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticate: async (
      credentials: Credentials,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'credentials' is not null or undefined
      assertParamExists("authenticate", "credentials", credentials);
      const localVarPath = `/v1/users/authenticate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        credentials,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (user: User, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'user' is not null or undefined
      assertParamExists("createUser", "user", user);
      const localVarPath = `/v1/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        user,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser: async (
      userId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("deleteUser", "userId", userId);
      const localVarPath = `/v1/users/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsers: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (
      userId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getUser", "userId", userId);
      const localVarPath = `/v1/users/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserByEmail: async (
      body: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("getUserByEmail", "body", body);
      const localVarPath = `/v1/users/getbyemail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isEmailUnavailable: async (
      body: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("isEmailUnavailable", "body", body);
      const localVarPath = `/v1/users/isemailtaken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isUserSiteAdmin: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/users/issiteadmin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PasswordResetData} passwordResetData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword: async (
      passwordResetData: PasswordResetData,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordResetData' is not null or undefined
      assertParamExists(
        "resetPassword",
        "passwordResetData",
        passwordResetData
      );
      const localVarPath = `/v1/users/forgot/reset`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordResetData,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PasswordResetRequestData} passwordResetRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPasswordRequest: async (
      passwordResetRequestData: PasswordResetRequestData,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordResetRequestData' is not null or undefined
      assertParamExists(
        "resetPasswordRequest",
        "passwordResetRequestData",
        passwordResetRequestData
      );
      const localVarPath = `/v1/users/forgot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordResetRequestData,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      userId: string,
      user: User,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("updateUser", "userId", userId);
      // verify required parameter 'user' is not null or undefined
      assertParamExists("updateUser", "user", user);
      const localVarPath = `/v1/users/updateuser/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        user,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateResetToken: async (
      token: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists("validateResetToken", "token", token);
      const localVarPath = `/v1/users/forgot/reset/validate/{token}`.replace(
        `{${"token"}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyCaptchaToken: async (
      token: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists("verifyCaptchaToken", "token", token);
      const localVarPath = `/v1/users/captcha/{token}`.replace(
        `{${"token"}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Credentials} credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authenticate(
      credentials: Credentials,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(
        credentials,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      user: User,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(
        user,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUser(
      userId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(
        userId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllUsers(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllUsers(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      userId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(
        userId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserByEmail(
      body: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByEmail(
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isEmailUnavailable(
      body: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.isEmailUnavailable(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isUserSiteAdmin(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.isUserSiteAdmin(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {PasswordResetData} passwordResetData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPassword(
      passwordResetData: PasswordResetData,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(
        passwordResetData,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {PasswordResetRequestData} passwordResetRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPasswordRequest(
      passwordResetRequestData: PasswordResetRequestData,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resetPasswordRequest(
          passwordResetRequestData,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} userId
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      userId: string,
      user: User,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(
        userId,
        user,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateResetToken(
      token: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.validateResetToken(token, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyCaptchaToken(
      token: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyCaptchaToken(token, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @param {Credentials} credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticate(credentials: Credentials, options?: any): AxiosPromise<User> {
      return localVarFp
        .authenticate(credentials, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(user: User, options?: any): AxiosPromise<string> {
      return localVarFp
        .createUser(user, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(userId: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteUser(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsers(options?: any): AxiosPromise<Array<User>> {
      return localVarFp
        .getAllUsers(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(userId: string, options?: any): AxiosPromise<User> {
      return localVarFp
        .getUser(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserByEmail(body: string, options?: any): AxiosPromise<User> {
      return localVarFp
        .getUserByEmail(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isEmailUnavailable(body: string, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .isEmailUnavailable(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isUserSiteAdmin(options?: any): AxiosPromise<boolean> {
      return localVarFp
        .isUserSiteAdmin(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PasswordResetData} passwordResetData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(
      passwordResetData: PasswordResetData,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .resetPassword(passwordResetData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PasswordResetRequestData} passwordResetRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPasswordRequest(
      passwordResetRequestData: PasswordResetRequestData,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .resetPasswordRequest(passwordResetRequestData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(
      userId: string,
      user: User,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .updateUser(userId, user, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateResetToken(token: string, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .validateResetToken(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyCaptchaToken(token: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .verifyCaptchaToken(token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for authenticate operation in UserApi.
 * @export
 * @interface UserApiAuthenticateRequest
 */
export interface UserApiAuthenticateRequest {
  /**
   *
   * @type {Credentials}
   * @memberof UserApiAuthenticate
   */
  readonly credentials: Credentials;
}

/**
 * Request parameters for createUser operation in UserApi.
 * @export
 * @interface UserApiCreateUserRequest
 */
export interface UserApiCreateUserRequest {
  /**
   *
   * @type {User}
   * @memberof UserApiCreateUser
   */
  readonly user: User;
}

/**
 * Request parameters for deleteUser operation in UserApi.
 * @export
 * @interface UserApiDeleteUserRequest
 */
export interface UserApiDeleteUserRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiDeleteUser
   */
  readonly userId: string;
}

/**
 * Request parameters for getUser operation in UserApi.
 * @export
 * @interface UserApiGetUserRequest
 */
export interface UserApiGetUserRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiGetUser
   */
  readonly userId: string;
}

/**
 * Request parameters for getUserByEmail operation in UserApi.
 * @export
 * @interface UserApiGetUserByEmailRequest
 */
export interface UserApiGetUserByEmailRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiGetUserByEmail
   */
  readonly body: string;
}

/**
 * Request parameters for isEmailUnavailable operation in UserApi.
 * @export
 * @interface UserApiIsEmailUnavailableRequest
 */
export interface UserApiIsEmailUnavailableRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiIsEmailUnavailable
   */
  readonly body: string;
}

/**
 * Request parameters for resetPassword operation in UserApi.
 * @export
 * @interface UserApiResetPasswordRequest
 */
export interface UserApiResetPasswordRequest {
  /**
   *
   * @type {PasswordResetData}
   * @memberof UserApiResetPassword
   */
  readonly passwordResetData: PasswordResetData;
}

/**
 * Request parameters for resetPasswordRequest operation in UserApi.
 * @export
 * @interface UserApiResetPasswordRequestRequest
 */
export interface UserApiResetPasswordRequestRequest {
  /**
   *
   * @type {PasswordResetRequestData}
   * @memberof UserApiResetPasswordRequest
   */
  readonly passwordResetRequestData: PasswordResetRequestData;
}

/**
 * Request parameters for updateUser operation in UserApi.
 * @export
 * @interface UserApiUpdateUserRequest
 */
export interface UserApiUpdateUserRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiUpdateUser
   */
  readonly userId: string;

  /**
   *
   * @type {User}
   * @memberof UserApiUpdateUser
   */
  readonly user: User;
}

/**
 * Request parameters for validateResetToken operation in UserApi.
 * @export
 * @interface UserApiValidateResetTokenRequest
 */
export interface UserApiValidateResetTokenRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiValidateResetToken
   */
  readonly token: string;
}

/**
 * Request parameters for verifyCaptchaToken operation in UserApi.
 * @export
 * @interface UserApiVerifyCaptchaTokenRequest
 */
export interface UserApiVerifyCaptchaTokenRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiVerifyCaptchaToken
   */
  readonly token: string;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @param {UserApiAuthenticateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public authenticate(
    requestParameters: UserApiAuthenticateRequest,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .authenticate(requestParameters.credentials, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiCreateUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public createUser(
    requestParameters: UserApiCreateUserRequest,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .createUser(requestParameters.user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiDeleteUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public deleteUser(
    requestParameters: UserApiDeleteUserRequest,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .deleteUser(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getAllUsers(options?: any) {
    return UserApiFp(this.configuration)
      .getAllUsers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiGetUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUser(requestParameters: UserApiGetUserRequest, options?: any) {
    return UserApiFp(this.configuration)
      .getUser(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiGetUserByEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserByEmail(
    requestParameters: UserApiGetUserByEmailRequest,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .getUserByEmail(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiIsEmailUnavailableRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public isEmailUnavailable(
    requestParameters: UserApiIsEmailUnavailableRequest,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .isEmailUnavailable(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public isUserSiteAdmin(options?: any) {
    return UserApiFp(this.configuration)
      .isUserSiteAdmin(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiResetPasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public resetPassword(
    requestParameters: UserApiResetPasswordRequest,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .resetPassword(requestParameters.passwordResetData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiResetPasswordRequestRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public resetPasswordRequest(
    requestParameters: UserApiResetPasswordRequestRequest,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .resetPasswordRequest(requestParameters.passwordResetRequestData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUser(
    requestParameters: UserApiUpdateUserRequest,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .updateUser(requestParameters.userId, requestParameters.user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiValidateResetTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public validateResetToken(
    requestParameters: UserApiValidateResetTokenRequest,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .validateResetToken(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiVerifyCaptchaTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public verifyCaptchaToken(
    requestParameters: UserApiVerifyCaptchaTokenRequest,
    options?: any
  ) {
    return UserApiFp(this.configuration)
      .verifyCaptchaToken(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
